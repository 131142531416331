body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  line-height: 1.6;
  direction: rtl;
  text-align: right;
  background-color: #f8f9fa;
}

.landing-page {
  color: #333;
}

.header {
  background-color: #662084;
  color: #fff;
  padding: 50px 20px;
  text-align: center;
}

.header .cta-button {
  background-color: #fff;
  color: #662084;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.header .cta-button:hover {
  background-color: #ddd;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.intro-section, .market-section, .cta-section {
  background-color: #fff;
  padding: 50px 20px;
  margin: 20px 0;
}

.features-section {
  background-color: #e9ecef;
  padding: 50px 20px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.feature {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cta-section {
  background-color: #662084;
  color: #fff;
  text-align: center;
}

.cta-section .cta-button {
  background-color: #fff;
  color: #662084;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
}

.fileViewerStyle{
  width: 200%;
}